/**
* * Rest style
*/
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::selection {
  background-color: $color-blue;
  color: #fff;
}
html {
  box-sizing: border-box;
  font-size: 62.5%; //10px===1rem
  // overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(300px);
  scroll-snap-type: y mandatory;
  height: auto;
}

body {
  font-family: "Sans-Mobile", "sans-serif" !important;
  font-size: 62.5%;
  font-weight: 400;
  color: $color-black;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}
.dashboard {
  height: 100%;
}
.app {
  height: 100%;
}

a,
button {
  cursor: pointer;
}



.input:focus {
  outline: none;
  outline-offset: none;
}

.MuiLinearProgress-root {
  transform: rotate(180deg) !important;
  background-color: #fff !important;
}

.dashboard {
  background: rgb(228, 239, 255);
  background: linear-gradient(243deg, rgba(228, 239, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.active {
  color: #000;
}

.Toastify__toast-body {
  font-size: 1.4rem !important;
  font-family: "Sans-Mobile";
  font-weight: 400;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}

@media only screen and (max-width: 764px) {
  // .active{
  //   color: #0056FF;
  //   border: 2px solid #0056FF;
  //   border-radius: 100%;
  // }
}
